@import '../../../theme/common/base.scss';

.form-wrapper {
  // box-shadow: 0px 0px 18px 3px #ddd;
  border: 1px solid $grey-color;
  margin: 0 auto;
  border-radius: 5px;
  background: $white-color;
  width: 50%;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 575px) {
    padding: 30px 20px;
  }
  .input-div {
    width: 80%;
  }
}

.form-content {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 15px;
}
