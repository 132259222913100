@import "../../../theme/common/base.scss";

header {
  padding: 7px 15px;
  width: 100%;
  background: $primary-color;
  box-shadow: 0 9px 7px rgba(0, 0, 0, 0.1);
  z-index: 11;
  .dropdown-toggle {
    img {
      cursor: pointer;
    }
  }
  .dropdown-toggle:after {
    cursor: pointer;
  }
  .brand-logo {
    min-width: 260px;
    text-align: left;
    @media (max-width: 575px) {
      min-width: 120px;
      max-width: 120px;
    }
    img {
      max-width: 180px;
      @media (max-width: 575px) {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}
.default-layout-navbar {
  .dropdown-toggle {
    span {
      display: none;
    }
  }
}
#UserDropdown {
  img {
    width: 50px;
  }
}
.navbar-nav {
  .search-form {
    position: relative;
    margin-right: 15px;
    i {
      font-size: 20px;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      color: #626262;
    }
    input {
      background-color: transparent;
      border: none;
      cursor: pointer;
      width: 0;
      height: 35px;
      padding: 0 0 0 20px;
      position: relative;
      -webkit-transition: width 400ms ease, background 400ms ease;
      transition: width 400ms ease, background 400ms ease;
      &:focus {
        background-color: transparent;
        border-bottom: 1px solid #626262;
        border-radius: 0;
        padding-left: 30px;
        background-position: 5px 7px;
        cursor: text;
        outline: 0;
        width: 160px;
      }
    }
  }
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item {
  margin-left: 24px;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown {
  position: relative;
}
.nav-item {
  a {
    position: relative;
    display: inline-block;
  }
}
.navbar .navbar-menu-wrapper .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.count {
  background: $black-color1;
  border-radius: 100%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  padding-left: 0;
  position: absolute;
  top: 0;
  right: -15px;
  text-align: center;
  line-height: 1.6;
  color: #fff;
  @media (max-width: 575px) {
    width: 17px;
    height: 17px;
    right: -12px;
    font-size: 11px;
    top: 3px;
  }
}
.humburger_icon {
  margin-left: auto;
  img {
    cursor: pointer;
  }
}

.count-indicator {
  i {
    img {
      @media (max-width: 575px) {
        width: 15px;
      }
    }
  }
}

.profile-drop {
  .dropdown-toggle {
    img {
      width: 50px;
      @media (max-width: 575px) {
        width: 30px;
      }
    }
  }
  .MuiList-root {
    li {
      border-bottom: 1px solid #f5f5f5;
      &:last-child {
        border: 0;
      }
    }
  }
}

.nav-user {
  h6 {
    color: $white-color;
  }
  span {
    color: $white-color;
  }
  svg {
    width: 50px;
    cursor: pointer;
    path {
      fill: $white-color;
    }
  }
}
