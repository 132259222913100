$primary-white: "white";
$primary-black: "black";
@import "./base.scss";

@import url("../../../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,400i,500,600,700&display=swap");
* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  color: #4d4d4f;
  word-break: break-word;
  white-space: normal;
  margin: 0px;
  padding: 0px;
}

body,
html {
  height: 100%;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
body {
  font-size: $f16;
}
iframe {
  border: none !important;
}
h1, h2, h3, h4, h5, h6 {
  color: #4d4d4f;
  font-weight: 600;
  margin: 0;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
  @media (max-width: 767px) {
    font-size: 1.4rem;
  }
}
h4 {
  font-size: 1.4rem;
  @media (max-width: 767px) {
    font-size: 1.2rem;
  }
}
h5 {
  font-size: 1.2rem;
}
h6 {
  font-size: 1rem;
}

.height-vh {
  min-height: calc(100vh - 120px);
}
.shadow {
  box-shadow: 0 0.1rem 0.7rem 0.1rem rgba(0, 0, 0, 0.15) !important;
}
.input-div {
  margin: 0 0 15px !important;
  /** Iphone4/4s Landscape**/
  /* iPhone 4/4S */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
    margin-bottom: 5px !important;
  }
  /** Iphone4/4s Landscape**/
  /* iPhone 4/4S */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
    margin-bottom: 5px !important;
  }
  @media (max-width: 330px) {
    margin: 0 0 10px !important;
  }
  input {
    border: 1px solid #522da8;
    font-size: 16px;
    padding: 10px !important;
    line-height: 1;
    display: $block;
    box-sizing: border-box;
    @media (max-width: 767px) {
      height: 40px;
      padding: 8px 12px !important;
      font-size: $f12+2;
    }
  }
  .input-field {
    width: 100%;
    label {
      position: $static;
      font-size: $f14+1;
      margin: 0 0 5px;
      color: #522da8 !important;
      -webkit-text-fill-color: #522da8 !important;
    }
    label + .MuiInput-formControl {
      margin: 0;
    }
  }
  .hasError {
    label {
      color: $red-color !important;
      -webkit-text-fill-color: $red-color !important;
    }
    input {
      border: 1px solid $red-color;
    }
  }
}

a {
  font-size: $f14+1;
  line-height: 1.7;
  color: #545151;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  &:focus {
    outline: $none !important;
  }
  &:hover {
    text-decoration: none;
    color: $black-color1;
  }
}

p {
  font-size: 16px;
  line-height: 1.7;
  color: #4d4d4f;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border-color: 0;
  cursor: pointer;
}

input,
textarea {
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
label {
  font-weight: 500;
  margin-bottom: 0;
}
.btn {
  line-height: normal;
  word-break: keep-all;
  white-space: nowrap;
}
.btn-sm {
  min-width: 80px;
}
.btn-md {
  display: $inline-block;
  padding:8px 12px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  min-width: 130px;
  min-height: 42px;   
}

.btn-lg {
  display: $inline-block;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500; 
  text-align: center;
  i {
    margin-left: 7px;
    float: right;
    margin-top: -1px;
  }
}
.btn-primary {
  background: $primary-color;
  border: 1px solid $primary-color;
  color: #fff;
  border-radius: 7px;
  text-transform: capitalize;
  &:hover {
    background: $primary-dark;
    border: 1px solid $primary-dark;
  }
}

.btn-outline-secondary {
  border-color: $black-color1;
  color: $black-color1;
  &:hover {
    background-color: $black-color1;
    border-color: $black-color1;
    i {
      img {
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }
    }
  }
  &:focus {
    box-shadow: $none;
  }
}

.btn-outline-primary {
  border-color: #4d4d4f;
  color: #4d4d4f;
  &:hover, &:focus, &:active {
    background-color: #4d4d4f;
    border-color: #4d4d4f;
    color: #fff;
    box-shadow: $none;
  }
}

textarea {
  outline: $none;
  border: $none;
}

textarea.form-control {
  min-height: 140px;
  padding: 0.675rem 0.75rem;
}

.form-control {
  font-size: $f16;
  color: #909090;
  border-color: #e5e5e5;
  line-height: 1;
  display: $block;
  min-height: 48px;
  background: $white-color;
  width: 100%;
  // resize: none;
  &:focus {
    border-color: #909090;
  }
}

.log_reg_form label {
  font-weight: 400;
  font-size: $f16;
  position: $relative;
}

.error_msg {
  font-size: 13px;
  display: inline;
  left: 0;
  position: $absolute;
  line-height: 14px;
}

input::-webkit-input-placeholder {
  color: #eee;
}
input:-moz-placeholder {
  color: #eee;
}
input::-moz-placeholder {
  color: #eee;
}
input:-ms-input-placeholder {
  color: #eee;
}
textarea::-webkit-input-placeholder {
  color: #eee;
}
textarea:-moz-placeholder {
  color: #eee;
}
textarea::-moz-placeholder {
  color: #eee;
}
textarea:-ms-input-placeholder {
  color: #ddd;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 48px !important;
  border-color: #e5e5e5 !important;
}

.css-1pahdxg-control {
  box-shadow: 0 0 0 1px #909090 !important;
}

/*---------------------------------------------*/

.form-group {
  margin: 0 0 15px;
  position: $relative;
  @media (max-width: 767px) {
    margin-bottom: 1.3rem;
  }
  input {
    color: $black-color;
  }
}

.front-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.log_reg_form {
  width: 100%;
  padding: 40px 60px;
  @media (max-width: 991px) {
    padding: 30px 15px;
  }
}

/****/
.header-main {
  padding: 20px 0;
}

.login-signup-container {
  box-shadow: 0px 0px 18px 3px #ddd;
  background: $white-color;
  border-radius: 5px;
  margin: 15px 0;
}

/**/
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #153368 !important;
  border-color: #153368 !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #f54d58;
  border-color: #f54d58;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background: #333;
  border-color: #333;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary.focus,
.btn-primary:focus,
.form-control:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.multisteps_list li.completed i {
  font-size: 0;
}

.form_title {
  margin: 0 0 30px;
  i {
    margin-bottom: 15px;
    display: $inline-block;
  }
  h3 {
    margin: 0 0 10px;
  }
}

.ad_arrows {
  display: inline-flex;
  flex-direction: column;
  padding: 1px 0 0 10px;
  justify-content: center;
  vertical-align: bottom;
  position: $relative;
  top: -5px;
  .down-arrow {
    margin-top: 3px;
  }
  img {
    opacity: 0.3;
    cursor: pointer;
  }
  .ascending,
  .descending {
    opacity: 1;
  }
}
.card {
  border-radius: 7px !important;
}
.card-title {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column-reverse !important;
  }
  h3 {
    font-weight: 600;
  }
  span {
    font-weight: 400;
    font-size: 19px;
    margin: 0px;
    @media (max-width: 767px) {
      font-size: 17px;
    }
  }
}
div[data-test="sentinelEnd"] {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: -1;
  top: 0;
}
.MuiSelect-select option {
  text-transform: capitalize;
}
.drop-down .MuiSelect-root,
.drop-down .MuiSelect-select {
  text-transform: capitalize;
}
.drop-down .MuiSelect-root {
  text-align: center;
}