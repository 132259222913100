// _variable.scss
@font-face {
    font-family: 'Segoe UI';
    src: url('../../assets/fonts/SegoeUI-Bold.eot');
    src: url('../../assets/fonts/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SegoeUI-Bold.woff2') format('woff2'),
        url('../../assets/fonts/SegoeUI-Bold.woff') format('woff'),
        url('../../assets/fonts/SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../../assets/fonts/SegoeUI-Italic.eot');
    src: url('../../assets/fonts/SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SegoeUI-Italic.woff2') format('woff2'),
        url('../../assets/fonts/SegoeUI-Italic.woff') format('woff'),
        url('../../assets/fonts/SegoeUI-Italic.svg#SegoeUI-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../../assets/fonts/SegoeUI.eot');
    src: url('../../assets/fonts/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SegoeUI.woff2') format('woff2'),
        url('../../assets/fonts/SegoeUI.woff') format('woff'),
        url('../../assets/fonts/SegoeUI.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../../assets/fonts/SegoeUI-Semibold.eot');
    src: url('../../assets/fonts/SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SegoeUI-Semibold.woff2') format('woff2'),
        url('../../assets/fonts/SegoeUI-Semibold.woff') format('woff'),
        url('../../assets/fonts/SegoeUI-Semibold.svg#SegoeUI-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../../assets/fonts/SegoeUI-BoldItalic.eot');
    src: url('../../assets/fonts/SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SegoeUI-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/SegoeUI-BoldItalic.woff') format('woff'),
        url('../../assets/fonts/SegoeUI-BoldItalic.svg#SegoeUI-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}
