// _base.scss
@import "./variables.scss";

$primary-color: #0091ff;
$secondary-color: #1da713;
$primary-dark: #0888e9;
$secondary-dark: #0d8704;
$purple-color: #b01ff8;
$white-color: #ffffff;
$black-color: #000000;
$base-color: #535050;
$grey-color: #707070;
$grey-color1: #8b8b8b;
$grey-color2: #a2a2a2;
$grey-color3: #f2f2f2;
$grey-color4: #f8f8f8;
$grey-color5: #808080;
$grey-color6: #f5f5f5;
$grey-color7: #eeeeee;
$grey-color8: #dedede;
$grey-color9: #a3a3a3;
$grey-light-color: #d2d2d2;
$primary-disable-color: #cfeaff;
$black-color: #000;
$black-color1: #153368;
$gold-color: #fac802;
$dark-green: #247801;
$dark-red: #f30031;
$orange-color: #ff8000;
$red-color: #ff1010;
$gray: #888;
$transparent: transparent;
$default-font: "Segoe UI";

$none: none;
$block: block;
$inline-block: inline-block;
$d-flex: flex;

$static: static;
$relative: relative;
$absolute: absolute;
$fixed: fixed;

$f12: 12px;
$f14: 14px;
$f16: 16px;
