@media (max-width:767px){
    .driver_title{
        flex-direction:column-reverse;
    }
    button {
        margin-bottom: 10px;
    }
}
.star-ratings svg {
    width: 21px!important;
}
.driver_rating {
    padding: 20px 0;
}
.table_title {
    margin: 0 0 15px;
}