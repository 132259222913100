@import '../../theme/common/base.scss';
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
}

.front-form {
  width:100%;
  min-height: 100vh;
  @media (min-width:575px){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .front_form_group {
    width:100%;
    margin:15px 0
  }
}