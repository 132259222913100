@import "../../../theme/common/base.scss";

.drawer-menu .MuiPaper-root::-webkit-scrollbar {
  width: 8px;
}
.drawer-menu .MuiPaper-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.drawer-menu .MuiPaper-root::-webkit-scrollbar-thumb {
  background-color: #f2f2f2;
  outline: 1px solid slategrey;
}

.drawer-paper {
  position: $relative;
}
.drawer-menu {
  .MuiPaper-root {
    z-index: 11;
    background-color: $black-color1;
    padding: 60px 0;    
    box-shadow: 0 0 20px #a3a3a3;
    @media (max-width: 1024px) {
      transform: translateX(-280px);
      -webkit-transform: translateX(-280px);
    }
  }
}
.MuiDrawer-paperAnchorDockedLeft {
  border: 0 !important;
}
.MuiDrawer-paper {
  overflow-x: hidden;
}
#app-drawer {
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-content: center;
    margin: 35px 0;
    width: 100%;
    position: $relative;
    z-index: 11;
    cursor: pointer;
  }
  .MuiList-padding {
    width: 100%;
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 60px;
    @media (max-height: 560px), screen and (orientation: portrait) {
      padding-bottom: 0;
    }
  }
}
.drawer-view {
  width: 280px;
  height: 100%;
  left: 0;
  top: 0;
  position: $relative;
  z-index: 11;
  .top-shape {
    position: $absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }
  .bottom-shape {
    transition: $none;
    position: $absolute;
    left: -1px;
    bottom: 0;
    width: 85%;
    pointer-events: $none;
  }
}
.list-item {
  font-size: 16px;
  margin: 0px 0 10px;
  color: $white-color;
  justify-content: center;
  padding: 10px 0!important;
  transition: all 0.5s ease-in-out; 
  cursor: pointer;   
  &:before {
    content: "";
    background:$white-color;
    border-radius: 5px;
    width: 6px;
    height: 6px;
    position: $absolute;
    top: 19px;
    left: -14px;
    opacity: 0;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
  }
  span {
    color: $white-color;
  }
  &.active-tab, &:hover {
    &:before {
      opacity: 1;
    }
  }  
  .MuiListItemText-root {
    margin:0;
  }
}

.close-image {
  cursor: pointer;
  @media screen and (max-width: 820px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    position: $absolute;
    padding: 10px;
    z-index: 11;
    top: 1%;
    right: 2%;
  }
}
.MuiBackdrop-root {
  background-color: transparent !important;
}

.logout_menu {
  background: $black-color1;
  position: $fixed;
  bottom: 0;
  width: 220px;
  left: 0;
  display: flex;
  padding-left: 30px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 15px 15px 30px;
  cursor: pointer;
  @media (max-height: 560px), screen and (orientation: portrait) {
    position: $static;
  }
  label {
    color: $white-color;
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
  }
  i {
    min-width: 50px;
    text-align: center;
    display: $inline-block;
  }
  img {
    width: 24px;
    height: auto;
  }
}