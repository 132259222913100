#screen-content {
  padding-top: 100px;
  padding-bottom: 20px;
  @media (min-width: 1025px) {
    margin-left: 280px;
  }
}
.close-drawer {
  background: #4a57cc;
  color: #fff;
  font-size: 16px;
  width: 42px;
  height: 42px;
  line-height: 44px;
  top: 0;
  z-index: 9999999;
  left: -200px;
  text-align: center;
  position: fixed;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.screen-hoc-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.screen-hoc-content {
  display: flex;
  width: 100%;
  justify-content: center;
}
