@import '../../../theme/common/base.scss';

.form-checkbox {
  padding-left: 32px;
  position: $relative;}

.checkmark {
  position: $absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: $white-color;
  border: 2px solid $black-color1;
  border-radius: 0;
}

.form-checkbox input ~ .checkmark {
  background-color: $white-color;
}

.form-checkbox input:checked ~ .checkmark {
  background-color: $black-color1;
  border-color: $transparent;
}

.checkmark:after {
  content: "";
  position: $absolute;
  display: $none;
}

.form-checkbox input:checked ~ .checkmark:after {
  display: $block;
}

.checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid $white-color;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
