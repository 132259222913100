@import '../../theme/common/base.scss';
#screen-content {
  .card-header {
    background: $transparent;
    border-bottom:0;
    padding:0 0 15px;
  }
}
.table-search {
  .MuiFormControl-root {
    width:100%;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  position: $fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height:100%;
  z-index: 11;
}
.dropzone-div {
  position: $fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 0.15s linear;
  -webkit-transition: opacity 0.15s linear;
  @media (min-width: 575px) {
    width: calc(100% + 17px);
  }
  .dialog-lg {
    max-width: 1025px;
    @media (min-width: 1024px) {
      min-width: 990px;
    }
  }
  .dropzone-dialog {
    max-width: 600px;
    display: flex;
    align-items: center;
    position: $relative;
    width: auto;
    margin: 1.75rem auto;
    z-index: 11;
    @media (min-width: 575px) {
      min-height: calc(100% - 3.5rem);
    }
    .dropzone-content {
      position: $relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      width: calc(100% - 20px);
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      margin: 0 10px;
      .dropzone-body {
        padding: 1.8rem;
        border-radius: 12px;
        .d-flex {
          .form-group {
            margin: 0;
          }
        }
        .form-group {
          label {
            font-weight: 500;
            margin-bottom: 7px;
          }
        }
      }
    }

    .flex-column {
      flex-direction: row !important;
      justify-content: flex-end !important;
    }
  }
}
