@import "../../../theme/common/base.scss";

.pagination-div {
  align-items:center;
  margin:15px 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;
  @media (max-width: 767px) {   
    padding: 0;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
  .pagination-text {
    margin: 0;
    color: #4d4d4f;
    font-size: 16px;
    font-weight: 400;
    padding:0 15px 0 0;
    @media (max-width: 767px) {
      flex: auto;
      font-size:15px;
      padding:10px 0;
    }
    span {
      font-weight: 500;
      color: #000;
    }
  }

  .page-index {
    border: 1px solid #d1d1d2;
    background-color: #ffffff;
    border-radius: 5px;
    width: 34px;
    height: 34px;
    line-height: 0.8;
    font-size: 18px;
    text-align: center;
    margin: 0 3px;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    cursor: pointer;
    label {
      position: relative;
      margin: auto;
      word-break: keep-all;
    }
    button {
      border: 0;
      padding: 0;
      background: transparent;
      &:disabled {
        img {
          opacity: 0.5;
        }
      }
    }
  }
}
.pagination-div > div {
  justify-content: space-around;
  cursor: pointer;
  @media (max-width: 991px) {
    flex: 0 0 50%;
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    flex: auto;
  }
}

.page-index-container {
  flex-direction: row;
  justify-content: center;
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    float: right;
  }
}

.svg-light-gray {
  filter: invert(99%) sepia(0%) saturate(602%) hue-rotate(148deg) brightness(94%) contrast(78%);
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  ul,
  li {
    min-width: 16px;
    text-align: center;
    margin: 0px 5px;
    list-style-type: none;
  }
  li {
    cursor: pointer;
    a {
      font-size: 16px;
    }
  }
  .break-me {
    margin: 0px;
  }
  .active {
    color: $primary-color;
  }
  .next {
    a {
      cursor: pointer;
    }
  }
}
